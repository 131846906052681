import React from 'react'
import Seo from '../components/Seo'

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404" description="Page not found" />
      <h1>404</h1>
    </>
  )
}

export default NotFoundPage
